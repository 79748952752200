<template>
  <div class="iss-main-grid" ref="mainRef">
    <a-spin :spinning="spinning" size="large">
      <div class="iss-search-wrap">
        <search-form :items="items" @fnSearch="handleFnSearch" />
      </div>
      <div class="iss-grid-wrap mt-16">
        <grid
          ref="gridRef"
          allow-selection
          :columns="columns"
          :code="$route.path"
          :url="url"
          :search="search"
          :btn-operation="btnOperation"
          :scroll="{ x: 1660, y: gridHeight }"
        >
          <template #autoSwitch="{ record }">
            <a-switch
              v-if="record.tagTypeCode !== 'custom'"
              :checked="record.autoSwitch"
              checked-children="启用"
              un-checked-children="禁用"
              @click="handleClickSwitch(record)"
            />
          </template>
          <template #operation="{ record }">
            <operation :options="getOptions(record)" :record="record" />
          </template>
          <template #executStatus="{ text }">
            <a-tag color="success" v-if="text === 'ExecuteSuccessfully'"
              >执行成功</a-tag
            >
            <a-tag color="processing" v-else-if="text === 'Running'">
              执行中
            </a-tag>
            <a-tag color="error" v-else-if="text === 'ExecFailure'">
              执行失败
            </a-tag>
            <!--          <a-tag color="warning" v-else-if="text === 'Cancel'">-->
            <!--            取消执行-->
            <!--          </a-tag>-->
            <a-tag color="warning" v-else> 待执行 </a-tag>
          </template>
        </grid>
      </div>
    </a-spin>
    <iss-clue-edit
      v-model:visible="visible"
      :init-value="activeItem"
      :member-group-list="memberGroupList"
      :score-model-list="scoreModelList"
      @fnOk="handleFnOkByEdit"
      :container="mainRef"
    />
  </div>
</template>

<script>
import { Spin, message, Tag, Switch } from 'ant-design-vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import operation from '@/components/operation';
import clueApi from '@/api/clue.js';
import { reactive, ref, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import * as icons from '@ant-design/icons-vue';
import IssClueEdit from '@/views/datacenter/cluemanage/edit';
// import { downloadExcel } from '@/utils';

export default {
  components: {
    ASpin: Spin,
    SearchForm,
    ATag: Tag,
    Grid,
    operation,
    IssClueEdit,
    ASwitch: Switch,
  },
  setup() {
    // const uploadUrl =
    //   process.env.VUE_APP_API_SERVER +
    //   process.env.VUE_APP_API_BASE_URL +
    //   clueApi.importLeadPoolDetailUrl;
    // const uploadMessageKey = 'upload';
    const state = reactive({
      search: {},
      spinning: false,
      visible: false,
      addActiveItem: {},
      addvisible: false,
      knowledge: null,
      manageListItem: '',
      activeItem: {},
      id: '',
      data: [],
      value: '',
      memberGroupList: [
        { value: '0', label: '所有联系人' },
        // { value: '1', label: '实名联系人' },
      ],
      scoreModelList: [],
    });
    // 线索池取消执行
    // const CancelExec = (data) => {
    //   let id = data.id
    //   clueApi.CancelRefreshScore('', {id})
    //       .then(() => {
    //         message.success('操作成功！');
    //       })
    //       .finally(() => {
    //         gridRef.value.refreshGrid();
    //       });
    // };

    const options = [
      {
        type: 'execute',
        icon: 'PlayCircleOutlined',
        permission: 'cluemanage:execute',
        label: '执行',
        fnClick: ({ id }) => {
          clueApi.refreshScore('cluemanage:execute', { id }).then(() => {
            message.success('操作成功');
            gridRef.value.refreshGrid();
          });
        },
        show: record => {
          if (record.executStatus === 'Running') {
            return false;
          } else {
            return true;
          }
        },
      },
      // {
      //   type: 'CancelExec',
      //   icon: 'CloseCircleOutlined',
      //   permission: 'group:CancelExec',
      //   label: '取消执行',
      //   fnClick: CancelExec,
      //   show: record => {
      //     if (record.executStatus === 'Running') {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //
      //   },
      // },
      {
        type: 'check',
        icon: 'EyeTwoTone',
        permission: 'cluemanage:check',
        label: '查看',
        fnClick: ({ id }) => router.push(`/leadscenter/cluemanage/${id}`),
      },
      {
        type: 'update',
        permission: 'cluemanage:update',
        label: '编辑',
        fnClick: record => {
          state.visible = true;
          state.activeItem = { ...record };
        },
        show: item => {
          if (item.executStatus === 'Running') {
            return false;
          } else {
            return true;
          }
        },
      },
      {
        type: 'delete',
        permission: 'cluemanage:delete',
        label: '删除',
        fnClick: ({ id }) => {
          clueApi.delete('cluemanage:delete', { ids: [id] }).then(() => {
            message.success('操作成功');
            gridRef.value.refreshGrid();
          });
        },
        show: item => {
          if (item.executStatus === 'Running') {
            return false;
          } else {
            return true;
          }
        },
      },
    ];
    clueApi.getMemberGroupList('').then(list => {
      list?.forEach(({ id, name }) => {
        state.memberGroupList.push({ value: id, label: name });
      });
    });
    clueApi.getLeadScoreModel('').then(list => {
      list?.forEach(({ id, name }) => {
        state.scoreModelList.push({ value: id, label: name });
      });
    });
    const router = useRouter();
    const gridRef = ref();
    const mainRef = ref();
    const handleFnSearch = value => {
      state.search = value;
    };
    const replaceFields = {
      children: 'child',
      title: 'name',
    };
    const handleClickAdd = () => {
      state.addvisible = true;
    };
    // const getFormImportResultByCacheData = (data, closeSetInterval) => {
    //   if (data.result) {
    //     clearInterval(closeSetInterval);
    //     message.success({
    //       content: `导入成功，成功${data.successCountss}条，失败${data.failCount}条。`,
    //       key: uploadMessageKey,
    //     });
    //   } else {
    //     clearInterval(closeSetInterval);
    //     message.error({ content: '导入失败', key: uploadMessageKey });
    //   }
    // };
    // const FormImportResultByCache = file => {
    //   let count = 0;
    //   let closeSetInterval = setInterval(function () {
    //     if (count < 50) {
    //       count++;
    //       let batchId = file.response.data.batchId;
    //       clueApi.getFormImportResultByCache('', { batchId }).then(data => {
    //         getFormImportResultByCacheData(data, closeSetInterval);
    //       });
    //     } else {
    //       // 清除定时器
    //       clearInterval(closeSetInterval);
    //       message.error({ content: '导入失败', key: uploadMessageKey });
    //     }
    //   }, 1000);
    // };
    return {
      ...toRefs(state),
      router,
      // CancelExec,
      handleClickAdd,
      gridRef,
      mainRef,
      icons,
      replaceFields,
      handleFnSearch,
      url: clueApi.pageUrl,
      gridHeight: document.body.clientHeight - 359,
      items: [
        { key: 'name', label: '名称' },
        {
          key: 'sourcesId',
          label: '数据来源',
          type: 'select',
          dataset: state.memberGroupList,
        },
        {
          key: 'scoreId',
          label: '评分模型',
          type: 'select',
          dataset: state.scoreModelList,
        },
      ],
      columns: [
        { dataIndex: 'name', title: '名称', width: 200, ellipsis: true },
        {
          dataIndex: 'sourcesName',
          title: '数据来源',
          width: 200,
          ellipsis: true,
        },
        {
          dataIndex: 'scoreName',
          title: '评分模型',
          customRender: ({ text }) => text || '-',
          width: 200,
          ellipsis: true,
        },
        {
          dataIndex: 'totalNumber',
          title: '线索总数',
          width: 120,
          ellipsis: true,
        },
        // { dataIndex: 'subType', title: '本院流失线索数' },
        {
          key: 'autoSwitch',
          title: '自动执行',
          slots: { customRender: 'autoSwitch' },
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'executStatus',
          title: '执行状态',
          slots: { customRender: 'executStatus' },
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'updateTime',
          title: '更新时间',
          width: 180,
          ellipsis: true,
        },
        {
          dataIndex: 'createTime',
          title: '创建时间',
          width: 180,
          ellipsis: true,
        },
        { dataIndex: 'userName', title: '创建人' },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 160,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'add',
          permission: 'cluemanage:add',
          fnClick: () => {
            state.visible = true;
            state.activeItem = {};
          },
        },
        // {
        //   type: 'importTemplate',
        //   label: '导入模板',
        //   icon: 'DownloadOutlined',
        //   permission: 'clue:importTemplate',
        //   fnClick: () => {
        //     clueApi
        //       .downloadImportUserTemplate('')
        //       .then(({ name, data }) => downloadExcel(name, data));
        //   },
        // },
        // {
        //   type: 'import',
        //   label: '导入',
        //   upload: {
        //     action: uploadUrl,
        //     method: 'post',
        //     accept: '.xls,.xlsx',
        //     name: 'multipartfile',
        //   },
        //   permission: 'clue:import',
        //   fnChange: async ({ file }) => {
        //     if (file.status === 'done') {
        //       if (file.response.data.result) {
        //         FormImportResultByCache(file);
        //         message.loading({
        //           content: '数据正在导入中...',
        //           key: uploadMessageKey,
        //           duration: 70,
        //         });
        //       } else {
        //         message.error({ content: '导入失败', key: uploadMessageKey });
        //       }
        //     }
        //   },
        // },
        // {
        //   type: 'export',
        //   label: '导出',
        //   icon: 'ExportOutlined',
        //   permission: 'clue:export',
        //   fnClick: ids => {
        //     if (ids.length) {
        //       clueApi
        //         .exportLeadPool('', ids)
        //         .then(({ name, data }) => downloadExcel(name, data));
        //     } else {
        //       message.info('请先选择数据');
        //     }
        //   },
        // },
      ],

      handleClickClassName: val => {
        state.manageListItem = val;
      },
      handleClickSwitch({ id, autoSwitch }) {
        autoSwitch = !autoSwitch;
        clueApi.updateAutoSwitch('', { id, autoSwitch }).then(res => {
          if (res) {
            message.success('操作成功');
            gridRef.value.refreshGrid();
          }
        });
      },
      handleFnOkByEdit: value => {
        let type = 'add';
        const { id } = value;
        id && (type = 'update');
        clueApi[type](`question:${type}`, value).then(() => {
          message.success('操作成功');
          state.visible = false;
          gridRef.value.refreshGrid();
        });
      },
      getOptions: item =>
        item.type === 'system'
          ? options.filter(i => i.type === 'check' || i.type === 'execute')
          : options,
    };
  },
};
</script>

<style lang="less" scoped>
//.iss-main {
//  height: calc(100vh - 120px);
//  overflow-y: auto;
//}
.main-tree {
  padding: 15px;
  border-radius: 8px;
  box-shadow: @box-shadow-base;
  //height: calc(100vh - 209px);
  //overflow-y: auto;
}

//.iss-tree {
//  height: calc(100vh - 310px);
//  overflow-y: auto;
//  .iss-tree-item {
//    display: flex;
//    align-items: center;
//    // 超出部分自动换行
//    white-space: pre-wrap;
//    word-break: break-word;
//    word-wrap: break-word;
//  }
//}
</style>
